// stylelint-disable scss/dollar-variable-empty-line-before

$baseDir: "./";

// COLORS
$color-black: #000;
$color-black-main: #2c2e30;
$color-section-title: #25262b;
$color-section-p: #272d31;
$color-white: #fff;
$color-blue: #2593e4;

// FONTS
// stylelint-disable-next-line
$font-primary: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen,
	ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-monserrat: "Montserrat", sans-serif;
$font-roboto: "Roboto", sans-serif;

$font-monserrat: "Montserrat", sans-serif;
$font-roboto: "Roboto", sans-serif;
$font-roboto-condensed: "Roboto Condensed", sans-serif;;


// SHADOWS
$shadow-cards-main: 0px 8px 16px rgba(37, 38, 43, 0.1);
$shadow-small-laptop: 0 vw(2) vw(4) rgba(0, 0, 0, 0.04);
$shadow-med: 0 0 2px rgba(0, 0, 0, 0.08), 0 8px 24px rgba(0, 0, 0, 0.08);
$shadow-med-laptop: 0 0 vw(2) rgba(0, 0, 0, 0.08),
	0 vw(8) vw(24) rgba(0, 0, 0, 0.08);
$shadow-large: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
$shadow-large-laptop: 0 0 vw(2) rgba(0, 0, 0, 0.12),
	0 0 vw(96) rgba(0, 0, 0, 0.16);

// BORDER-RADIUS
$br8: 8px;
$br8-lap: vw(8);

$br4: 4px;
$br4-lap: vw(4);

// EASING TRANSITIONS
$duration-normal: 0.4s;
$easing-back-out: cubic-bezier(
	0.25,
	0.74,
	0.22,
	1.1
); // http://cubic-bezier.com/#.25,.74,.22,1.1
$easing-back-in: cubic-bezier(
	0.47,
	-0.1,
	0.5,
	-0.06
); // http://cubic-bezier.com/#.47,-0.1,.5,-.06
$easing-out: ease-out;
$easing-in: ease-in;

// Settings
$desktop: 768;
$desktop-xl: 1440;
$laptop: 1440;
$tablet: 768;
$mobile: 390;
$desktop-vh: 750;

//size for function
$desktop-f: 1025;
$desktop-1650: 1650;
$desktop-xl-f: 1920;
$laptop-f: 1440;
$tablet-f: 860;
$mobile-f: 375;
$desktop-vh-f: 750;
