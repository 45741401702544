@function vw($value, $base: $laptop-f) {
	@return $value / $base * 100vw;
}

@function px($value, $base: $laptop-f) {
	@return $value * ($laptop * 1px) / $base;
}

@function vm($value, $base: $mobile-f) {
	@return $value / $base * 100vmin;
}

@function vmw($value, $base: $mobile-f) {
	@return $value / $base * 100vw;
}

@function vw1650($value, $base: $desktop-1650) {
	@return $value / $base * 100vw;
}

@function vc($value, $base: false, $current: false) {
	@if $base and $current {
		@return calc(#{$value} * (#{$current} / #{$base}));
	}

	@if $base {
		@return calc(#{$value} * (var(--width-current) / #{$base}));
	}

	@return calc(#{$value} * var(--width-multiplier));
}
