:root {
	--home-slider-color: #f2211c;
	--home-slider-bg: transparent;
}

.home {
	background-color: #eff2f5;

	.list {
		ul {
			padding-top: vc(14);
		}
	}

	.lead {
		padding-top: vc(170);
		transition: $duration-normal ease-out;

		&__slider {
			position: relative;
			width: 100%;
			min-height: vc(610);
			overflow: hidden;

			figure {
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
				width: vc(870);
				height: vc(440);

				@include mobile {
					left: vc(0);
					width: 100vw;
					height: vc(410);
					mask-image: linear-gradient(0deg,
							rgba(255, 255, 255, 0) 0%,
							#ffffff 40%);
				}
			}

			.swiper-slide {
				height: vc(440);

				@include mobile {
					height: auto;
				}
			}

			.swiper-wrapper {
				transition-timing-function: ease-in-out;
			}

			.container {
				position: relative;
			}

			>.container {
				padding-top: vc(21.5);

				@include desktop {
					display: flex;
				}

				@include mobile {
					margin-top: vc(30);
					margin-bottom: vc(72);
					padding: 0;
					width: 100vw;
					height: vc(40);
					overflow: hidden;
				}
			}

			&-name {
				margin-bottom: vc(30);
				padding-top: vc(30);
				color: currentColor;

				@include mobile {
					display: inline-block;
					margin-top: vc(296);
					margin-bottom: vc(20);
					padding: vc(1) vc(10) vc(0);
					background-color: currentColor;

					span {
						color: $color-white;
					}
				}
			}

			&-title {
				position: relative;
				z-index: 1;
				margin-bottom: vc(40);
				font-family: $font-roboto-condensed;
				font-style: normal;
				font-weight: 900;
				font-size: vc(66);
				line-height: vc(76);
				color: $color-section-title;
				transition: $duration-normal;

				@include mobile {
					padding-right: vc(30);
					font-size: vc(44);
					line-height: vc(55);
					letter-spacing: 0;
				}
			}

			&-btn {
				display: inline-block;
				padding: vc(14) vc(36);
				// background-color: currentColor;

				span {
					font-weight: 700;
					color: $color-white;
				}
			}

			&-pagination {
				position: relative;
				z-index: 1;
				display: flex;
				margin-left: vc(300);

				span {
					position: relative;
					display: flex;
					align-items: center;
					margin-left: vc(24);
					padding-left: vc(25);
					height: vc(30);
					font-family: $font-monserrat;
					color: currentColor;
					transition: 0.2s ease-out;
					cursor: pointer;
					transform: translateZ(0);
					will-change: color;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 1px;
						background: linear-gradient(180deg,
								rgba(255, 255, 255, 0.4) 0%,
								rgba(138, 141, 155, 0.4) 54.69%,
								rgba(255, 255, 255, 0.4) 100%);
					}

					&:first-child {
						padding-left: 0;
						margin-left: 0;

						&::before {
							display: none;
						}
					}

					&.swiper-pagination-bullet-active {
						color: rgba($color-black-main, 0.4);

						@include desktop {
							cursor: default;

							&:hover {
								opacity: 1;
							}
						}
					}

					@include desktop {
						&:hover {
							opacity: 0.6;
						}
					}
				}

				@include mobile {
					overflow-x: scroll;
					margin-left: 0;
					padding: 0 vc(40);
					height: vc(60);
					transition-delay: 0.3s !important;
				}
			}

			@include mobile {
				min-height: vc(700);
			}
		}

		@include mobile {
			padding-top: vc(92);
			// margin-top: vc(-92);
		}
	}

	&.home--dark {
		.header {
			.link__phone {
				color: $color-white;

				@include mobile {
					color: $color-section-title;
				}
			}

			&__burger {
				span {
					background: $color-white;
				}
			}

			&__description {
				color: $color-white;
			}

			&__divider {
				&::before {
					background: linear-gradient(180deg,
							rgba(255, 255, 255, 0) 0%,
							rgba(255, 255, 255, 0.4) 52.08%,
							rgba(255, 255, 255, 0) 100%);
				}
			}
		}

		.lead__slider {
			&-title {
				color: $color-white;
			}

			&-pagination {
				span {
					&::before {
						background: linear-gradient(180deg,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0.4) 52.08%,
								rgba(255, 255, 255, 0) 100%);
					}

					&.swiper-pagination-bullet-active {
						color: rgba($color-white, 0.4);
					}
				}
			}
		}
	}

	.header {
		color: var(--home-slider-color);

		@include mobile {
			background-color: var(--home-slider-bg);
		}
	}

	.lead {
		color: var(--home-slider-color);
		background-color: var(--home-slider-bg);
	}

	// 0

	.section {
		&--1 {
			padding-top: vc(110);

			.col {
				p {
					margin-bottom: vc(24);
					color: #494b57;

					@include mobile {
						margin-bottom: vc(20);
					}
				}

				&:nth-child(1) {
					flex: 0 0 vc(770);
					margin-right: vc(30);
					padding: vc(60);
					width: vc(770);
					height: vc(400);
					box-shadow: $shadow-cards-main;

					@include mobile {
						position: relative;
						left: vc(-10);
						margin: 0 0 vc(44);
						padding: vc(30) vc(20);
						width: vc(330);
						height: vc(184);
					}
				}
			}

			@include mobile {
				padding-top: vc(82);
			}
		}

		&--2 {
			padding-top: vc(120);
			padding-bottom: vc(94);

			p {
				margin-bottom: vc(34);
				max-width: vc(611);

				@include mobile {
					margin-bottom: vc(20);
				}
			}

			.row {
				flex-wrap: wrap;
				gap: vc(30);

				@include mobile {
					display: flex;
					gap: vc(10);
				}
			}

			@include mobile {
				padding-top: vc(90);
				padding-bottom: vc(80);
			}
		}

		&--3 {
			padding: vc(160) 0;
			background-color: #121213;
			overflow: hidden;

			.row--head {
				align-items: center;
			}

			.section__title {
				position: relative;
				z-index: 2;
				margin-bottom: 0;
				color: $color-white;
			}

			.slider {
				margin-top: vc(40);
				color: $color-white;
				width: vc(700);

				.swiper {
					&-wrapper {
						@include mobile {
							display: block;
						}
					}

					&-slide {
						position: relative;
						padding-left: vc(70);
						padding-top: vc(122);
						width: vc(700);

						img {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 1;
							height: vc(226);
							object-fit: contain;

							@include mobile {
								left: vc(-30);
								top: vc(-124);
							}
						}

						@include mobile {
							margin-bottom: vc(124);
							padding-top: 0;
							padding-left: 0;
							width: 100%;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				.slide-wrapper {
					position: relative;
					z-index: 2;
					padding: vc(50) vc(70) vc(45);
					background: #2d2f31;

					h3 {
						margin-bottom: vc(24);
						font-family: $font-roboto-condensed;
						font-size: vc(32);
						line-height: vc(40);
					}

					h4 {
						font-weight: 700;
					}

					p {
						color: $color-white;
					}

					.btn {
						display: flex;
						margin-left: vc(40);
						padding: vc(8) vc(34);
						border: 1px solid $color-blue;
						color: $color-white;

						&::before {
							z-index: -1;
							background-color: $color-blue;
						}

						&::after {
							background-color: transparent;
						}

						svg {
							margin-left: vc(5);
							width: vc(16);
							height: vc(16);
						}

						@include desktop {
							&:hover {
								color: $color-white;
							}
						}

						@include mobile {
							margin-left: 0;
							margin-top: vc(22);
							padding: vc(10) vc(0);
							width: vc(200);
						}
					}

					@include mobile {
						padding: vc(40) vc(40) vc(40) vc(20);
					}
				}

				.dropdown {
					&-wrapper {
						position: relative;
						overflow: hidden;
						height: 0;
						min-height: vc(205);

						&::after {
							content: "";
							position: absolute;
							bottom: -1px;
							left: 0;
							z-index: 2;
							width: 100%;
							height: vc(60);
							background: linear-gradient(180deg,
									rgba(255, 255, 255, 0) 0%,
									#2d2f31 100%);
							transition: 0.2s;
						}

						&.active {
							&::after {
								opacity: 0;
								visibility: hidden;
							}
						}

						&--disable {
							&::after {
								display: none;
							}
						}

						@include mobile {
							min-height: vc(238);
						}
					}

					&-content {
						// padding-bottom: vc(40);

						>* {
							margin-bottom: vc(15);

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					&-row {
						display: flex;
						margin-top: vc(20);
						height: vc(48);

						@include mobile {
							height: auto;
							display: block;
						}
					}

					&-btn {
						display: flex;
						align-items: center;
						color: #8d9aa7;
						transition: $duration-normal;
						cursor: pointer;

						svg {
							margin-left: vc(5);
							width: vc(16);
							height: vc(16);
							transition: $duration-normal;
						}

						&.active {
							svg {
								transform: rotate(180deg);
							}
						}

						@include desktop {
							&:hover {
								opacity: 0.6;
							}
						}
					}
				}

				&-pagination {
					position: relative;
					display: flex;
					align-items: center;
					margin-left: vc(6);
					margin-bottom: vc(12);
					padding: 0 vc(10);
					width: vc(220);
					color: $color-blue;

					&-fraction {
						position: absolute;
						top: vc(-13);

						&--current {
							right: 100%;
						}

						&--total {
							left: 100%;
						}
					}

					&-bar {
						width: 100%;
						height: 1px;
						background-color: #2d2f31;

						&--inner {
							height: 100%;
							background-color: currentColor;
							transition: $duration-normal ease-in-out;
						}
					}
				}

				&-controls {
					display: flex;
					margin-top: vc(10);
					margin-left: vc(25);
				}

				&-control {
					display: flex;
					align-items: center;
					justify-content: center;
					width: vc(32);
					height: vc(36);
					border: 1px solid $color-blue;
					color: $color-white;
					cursor: pointer;

					svg {
						width: vc(24);
						height: vc(24);
						transform: rotate(-90deg);
					}

					&--prev {
						margin-right: vc(10);

						svg {
							transform: rotate(90deg);
						}
					}

					&:disabled {
						border-color: #bbc5ce;
						color: #bbc5ce;
						cursor: auto;
					}
				}

				@include mobile {
					margin-top: vc(34);
					width: calc(100% + #{vc(40)});
				}
			}

			@include mobile {
				padding: vc(90) 0 vc(120);
			}
		}

		&--4 {
			padding: vc(162) 0 vc(158);
			background-color: $color-blue;

			.row {
				justify-content: space-between;
			}

			@include mobile {
				padding: vc(90) 0 vc(90);
			}
		}

		&--5 {
			padding-top: vc(162);
			padding-bottom: vc(160);

			.slider {
				.swiper {
					&-wrapper {
						justify-content: space-between;
					}

					&-slide {
						width: auto;
					}
				}

				@include mobile {
					width: vc(290);
					margin-bottom: vc(40);
				}
			}

			.section__title {
				margin-bottom: 0;
			}

			.row {
				&--head {
					justify-content: space-between;
					align-items: center;
					margin-bottom: vc(24);
				}
			}

			.btn {
				box-sizing: border-box;
				display: inline-block;
				padding: vc(7) vc(24);
				border: 1px solid $color-blue;
				color: #2d2f31;
				transition: $duration-normal;

				&::before {
					z-index: -1;
					background-color: $color-blue;
				}

				&::after {
					background-color: transparent;
				}

				&:hover {
					color: $color-white;
				}
			}

			@include mobile {
				padding-top: vc(60);
				padding-bottom: vc(70);
			}
		}
	}

	@include mobile {
		position: relative;
		overflow-x: hidden;
	}
}