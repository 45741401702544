.article {
    .header {
        background-color: $color-white;
    }

    > .container {
        margin-bottom: vc(100);

        @include mobile {
            padding-top: vc(36);
            margin-bottom: vc(60);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: vc(14);
        font-family: $font-roboto;
    }

    h1 {
        font-weight: 600;
        font-size: vc(66);
        line-height: vc(76);

        @include mobile {
            font-size: vc(40);
            line-height: vc(47);
        }
    }

    h2,
    h3 {
        font-weight: 500;
    }

    h4, h5 {
        font-weight: 400;
    }

    h2 {
        font-size: vc(44);
        line-height: vc(55);

        @include mobile {
            font-size: vc(32);
            line-height: vc(38);
        }
    }

    h3 {
        font-size: vc(32);
        line-height: vc(40);

        @include mobile {
            font-size: vc(24);
            line-height: vc(28);
        }
    }

    h4 {
        font-size: vc(28);
        line-height: vc(37);

        @include mobile {
            font-size: vc(20);
            line-height: vc(23);
        }
    }

    h5 {
        font-size: vc(21);
        line-height: vc(28);

        @include mobile {
            font-size: vc(16);
            line-height: vc(19);
        }
    }

    p,
    .list ul{
        margin-bottom: vc(14);
    }

    a {
        color: $color-blue;
    }

    .list {
        ul {
            padding-top: 0;
        }
    }
}